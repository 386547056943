import React from 'react';
import { shape, string, arrayOf, number } from 'prop-types';
import { withPrefix } from 'gatsby';
import InView from '../../components/InView/InView';
import medal from '../../img/medal.svg';

export default function Certifications({ certifications }) {
    return (
        <InView
            config={{
                rootMargin: '0px 0px 0px 0px',
            }}
        >
            <div className="certifications">
                {certifications.map(certificate => (
                    <a
                        className="certifications__item"
                        key={certificate.id}
                        href={withPrefix(
                            `/pdf/certificates/${certificate.file}.pdf`
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className="certifications__icon"
                            src={medal}
                            alt="ikona certyfikatu"
                        />
                        <h3 className="certifications__title">
                            {certificate.title}
                        </h3>
                        <span className="certifications__button">
                            Pobierz PDF
                        </span>
                    </a>
                ))}
            </div>
        </InView>
    );
}

Certifications.propTypes = {
    certifications: arrayOf(
        shape({
            id: number.isRequired,
            title: string.isRequired,
            file: string,
            date: string,
        })
    ).isRequired,
};
