import React from 'react';
import { shape, string, arrayOf, number } from 'prop-types';
import InView from '../../components/InView/InView';

export default function PriceList({ list }) {
    return (
        <InView
            config={{
                rootMargin: '0px 0px 0px 0px',
            }}
        >
            <div className="price-list">
                {list.map(item => (
                    <div className="price-list__item" key={item.id}>
                        <h3
                            className="price-list__title"
                            dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <div className="price-list__price">
                            <span>
                                {item.price}
                                <small>zł</small>
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </InView>
    );
}

PriceList.propTypes = {
    list: arrayOf(
        shape({
            id: number.isRequired,
            title: string.isRequired,
            price: string.isRequired,
        })
    ).isRequired,
};
