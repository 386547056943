import React from 'react';
import PropTypes from 'prop-types';
import InView from '../../components/InView/InView';

const Testimonials = ({ testimonials }) => (
    <InView
        config={{
            rootMargin: '0px 0px 0px 0px',
        }}
    >
        <div className="testimonials__wrapper">
            {testimonials.map(testimonial => (
                <article key={testimonial.id} className="testimonials__card">
                    <div className="testimonials__body">
                        {testimonial.quote}
                    </div>
                    <cite className="testimonials__author">
                        {' '}
                        – {testimonial.author}
                    </cite>
                </article>
            ))}
        </div>
        <div className="testimonials__more">
            Zobacz więcej na profilu na portalu{' '}
            <a
                href="https://www.facebook.com/pg/Anna-%C5%81uczycka-Fizjoterapia-w-ginekologii-308086713386126/reviews/"
                target="_blank"
                rel="noopener noreferrer"
            >
                Facebook
            </a>
        </div>
    </InView>
);

Testimonials.propTypes = {
    testimonials: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            quote: PropTypes.string,
            author: PropTypes.string,
        })
    ),
};

export default Testimonials;
