import React from 'react';
import phoneIcon from '../../img/icon-phone.svg';

export default function HeaderButtons() {
    return (
        <div className="header-buttons">
            <div className="header-buttons__phone-container">
                <a
                    href="sms: +48 509 606 931"
                    className="header-buttons__icon js-track-phone-click"
                    title="Anna Łuczycka - telefon +48 509 606 931"
                >
                    <img src={phoneIcon} alt="Anna Łuczycka - telefon" />
                    <span className="header-buttons__drop">
                        REJESTRACJA TYLKO SMS:
                        <br /> +48 509 606 931
                    </span>
                </a>
            </div>
        </div>
    );
}
