import React, { Component } from 'react';
import MovingBackground from '../MovingBackground/MovingBackground';
import bgImage from '../../img/annaluczycka-bg.jpg';
import logo from '../../img/logo.png';
import ScrollToButton from '../ScrollToButton/ScrollToButton';

export default class Intro extends Component {
    state = {
        isContentVisible: false,
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isContentVisible: true,
            });
        }, 200);
    }

    render() {
        const { isContentVisible } = this.state;
        const contentClass = isContentVisible
            ? 'intro__content--is-visible'
            : '';

        return (
            <div className="intro">
                <div className="intro__wrapper">
                    <MovingBackground
                        className="intro__bg"
                        backgroundImageUrl={bgImage}
                    />
                    <div className={`intro__content ${contentClass}`}>
                        <img
                            className="intro__logo"
                            src={logo}
                            alt="Anna Łuczycka - logo"
                        />
                        <h1 className="intro__title">
                            Anna <br /> Łuczycka
                        </h1>
                        <h2 className="intro__subtitle">
                            Fizjoterapia niemowląt oraz kobiet po porodzie
                        </h2>
                    </div>
                </div>
                <ScrollToButton selector=".js-content" />
            </div>
        );
    }
}
