import React from 'react';
import { shape, string, arrayOf, number } from 'prop-types';
import InView from '../../components/InView/InView';

export default function Services({ services }) {
    return (
        <InView
            config={{
                rootMargin: '0px 0px 0px 0px',
            }}
        >
            <div className="services">
                {services.map(service => (
                    <div className="services__item" key={service.id}>
                        <h3 className="services__title">{service.title}</h3>
                        <ul className="services__list">
                            {service.list.map((item, index) => {
                                return <li key={index}>{item}</li>;
                            })}
                        </ul>
                    </div>
                ))}
            </div>
        </InView>
    );
}

Services.propTypes = {
    services: arrayOf(
        shape({
            id: number.isRequired,
            title: string.isRequired,
            content: string,
        })
    ).isRequired,
};
