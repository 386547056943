import React from 'react';
import contact from './contact.json';
import bgImage from '../../img/contact-background.jpg';
import InView from '../../components/InView/InView';

export default function Contact() {
    return (
        <div
            className="contact__container"
            style={{ backgroundImage: `url(${bgImage})` }}
        >
            <InView
                config={{
                    rootMargin: '0px 0px 0px 0px',
                }}
            >
                <div>
                    <a
                        href="sms: +48 509 606 931"
                        className="js-track-phone-click"
                    >
                        +48 509 606 931
                    </a>
                    <br />
                    <strong className="contact__warning">
                        W celu umówienia wizyty proszę o wiadomość sms
                    </strong>
                    <span className="contact__separator" />
                    <a href="mailto: kontakt@annaluczycka.pl">
                        kontakt@annaluczycka.pl
                    </a>
                    <br />
                    <a
                        href={contact.mapUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Gabinet: {contact.address}
                    </a>
                </div>
            </InView>
        </div>
    );
}
