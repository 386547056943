import React, { Component } from 'react';
import Layout from '../components/Layout';
import Intro from '../components/Intro/Intro';
import HeaderButtons from '../components/HeaderButtons/HeaderButtons';
import Contact from '../components/Contact/Contact';
import Section from '../components/Section/Section';
import Services from '../components/Services/Services';
import servicesData from '../components/Services/servicesData';
import PriceList from '../components/PriceList/PriceList';
import priceListData from '../components/PriceList/priceListData';
import Certifications from '../components/Certifications/Certifications';
import certificationsData from '../components/Certifications/certificationsData';
import Testimonials from '../components/Testimonials/Testimonials';
import testimonialsData from '../components/Testimonials/testimonialsData';
import contact from '../components/Contact/contact.json';

const ExperienceIntro = () => (
    <div>
        Jestem absolwentką Akademii Wychowania Fizycznego w Gdańsku z tytułem
        magistra Fizjoterapii oraz absolwentką Uniwersytetu Gdańskiego na
        kierunku Biologia. Specjalizuję się w fizjoterapii niemowląt oraz kobiet
        po porodzie. Fizjoterapia niemowląt stała się moją pasją w oparciu o
        metodę NDT-Bobath. Terapię zaburzeń dna miednicy u kobiet oraz
        fizjoterapię w połogu prowadzę{' '}
        <a
            href="https://www.terapiadnamiednicy.pl/o-fizjoterapii-dna-miednicy"
            rel="noopener noreferrer"
            target="_blank"
        >
            Metodą Koszli
        </a>
        . Prywatnie jestem mamą dwóch synków.
    </div>
);

export default class IndexPage extends Component {
    render() {
        return (
            <Layout>
                <Intro />
                <HeaderButtons />
                <main className="main-content full-height js-content">
                    <Section title="OFERTA FIZJOTERAPEUTYCZNA">
                        <div className="section__row">
                            Zapraszam do gabinetu:{' '}
                            <a
                                href={contact.mapUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <strong>{contact.address}</strong>
                            </a>
                        </div>
                        <Services services={servicesData} />
                    </Section>
                    <Section title="Cennik">
                        <PriceList list={priceListData} />
                    </Section>
                    <Section title="Doświadczenie" intro={<ExperienceIntro />}>
                        <Certifications certifications={certificationsData} />
                    </Section>
                    <Section title="Rekomendacje" modificator="blue">
                        <Testimonials testimonials={testimonialsData} />
                    </Section>
                    <Contact />
                </main>
            </Layout>
        );
    }
}
